import '/node_modules/reset.css/reset.css';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
// import '/node_modules/chart.js/dist/chart.umd.js';
// import '/node_modules/chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min.js';
import '../scss/style.scss';
// import './slick01.js';
// import './slick02.js';
// import './slick03.js';
import SmoothScroll from '/node_modules/smooth-scroll/dist/smooth-scroll.polyfills.min.js';
import './header.js';
import './targetBlank.js';

(function() {
  /////////////////////////////////////////////////////////
  // 変数
  /////////////////////////////////////////////////////////

  // サンプル変数
  let windowH = getWindowHeight;

  setMainVisualArea();
  changeHeader();

  jQuery.noConflict();
  jQuery(jQuery(window).on('load', function() {
    var cContentsAttr = 'data-js_sp_menu_contents';
    var cBtnAttr = 'data-js_sp_menu_btn';

    var vContentsObj = jQuery('[' + cContentsAttr + ']');
    var vBtnObj = jQuery('[' + cBtnAttr + ']');

    ////////////////////////////////

    var cChildMasterAttr1 = 'data-js_menu_child_master';
    var cChildBtnAttr1 = 'data-js_menu_child_btn';
    var cChildContentsAttr1 = 'data-js_menu_contents';

    var vChildBtnObj1 = jQuery('[' + cChildBtnAttr1 + ']');

    var cLinkAttr1 = 'data-js_menu_link';
    var vLinkObj1 = jQuery('[' + cLinkAttr1 + ']');
    ////////////////////////////////

    var cClass = "is-current";

    ////////////////////////////////

    jQuery(window).resize(function() {
      fClose();
    });

    function fClose(){
      jQuery(vBtnObj).removeClass(cClass);
      jQuery(vContentsObj).slideUp();
      jQuery('html').css('overflow', 'auto');
    }

    jQuery(vBtnObj).on('click', function () {
      if(jQuery(this).hasClass(cClass)){
        fClose();
        let targetPos = getWindowHeight();
        if(location.pathname==='/'){
          let scrollPos = window.scrollY;
          if ( scrollPos < targetPos ) {
            $('.c_spMenu__wrap03, .c_spMenu__logo, .c_spMenu__btn').removeClass('-ver01');
          }else{
            $('.c_spMenu__wrap03, .c_spMenu__logo, .c_spMenu__btn').addClass('-ver01');
          }
        }else{
          $('.c_spMenu__wrap03, .c_spMenu__logo, .c_spMenu__btn').addClass('-ver01');
        }
      }else{
        jQuery(this).addClass(cClass);
        jQuery(vContentsObj).slideDown();
        jQuery('html').css('overflow', 'hidden');
        $('.c_spMenu__wrap03, .c_spMenu__logo, .c_spMenu__btn').removeClass('-ver01');
      }
    });
    jQuery(vLinkObj1).on('click', function () {
      jQuery(vBtnObj).removeClass(cClass);
      jQuery(vContentsObj).slideUp();
      $('.c_spMenu__wrap03, .c_spMenu__logo, .c_spMenu__btn').addClass('-ver01');
    });


    jQuery(vChildBtnObj1).on('click', function () {
      var vContents = jQuery(this).closest('[' + cChildMasterAttr1 + ']').find('[' + cChildContentsAttr1 + ']')[0];

      if(jQuery(this).hasClass(cClass)){
        jQuery(this).removeClass(cClass);
        jQuery(vContents).slideUp();
      }else{
        jQuery(this).addClass(cClass);
        jQuery(vContents).slideDown();
      }
    });
  }));

  jQuery.noConflict();
  jQuery(jQuery(window).on('load', function() {
    var cAttr = 'data-js_loading';
    var vObj = jQuery('[' + cAttr + ']');
    ////////////////////
    var cClass = 'is-loaded';

    jQuery(vObj).fadeOut('normal', function(){
      // コールバック関数
    });
  }));

  $(window).on("load", function(){
    initSlick01();
    modalMember();
    showScroll();
    var scroll = new SmoothScroll('a[href*="#"]');
    console.log(scroll)
    showBtnPagetop();
  });

  $(window).resize(function(){
    setMainVisualArea();
  })

  let timerId = null;
  $(window).on('load scroll', function(){
    // 間引き処理
    clearTimeout(timerId);
    timerId = setTimeout(function(){
      changeHeader();
      showScroll();
      showBtnPagetop();
    }, 80);
  });

  function getWindowHeight(){
    const windowH = window.innerHeight;
    return windowH;
  }

  function setMainVisualArea(){
    $("[data-js-setMainVisualArea-elm01]").css('height', windowH);
  }

  function initSlick01(){
    $("[data-js-slick01-elm]").not('.slick-initialized').slick({
      slidesToShow: 3,
      prevArrow: '<div class="l_topNews__sliderPrev">Prev</div>',
      nextArrow: '<div class="l_topNews__sliderNext">Next</div>',
      responsive: [
        {
          breakpoint: 768, // 399px以下のサイズに適用
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }

  function changeHeader(){
    let targetPos = getWindowHeight();
    if(location.pathname==='/'){
      let scrollPos = window.scrollY;
      console.log(windowH);
      if ( scrollPos < targetPos ) {
        $('.l_header01__navWrap, .l_header01__logo, .c_spMenu__logo, .c_spMenu__wrap03, .c_spMenu__btn').removeClass('-ver01');
      } else {
        $('.l_header01__navWrap, .l_header01__logo, .c_spMenu__logo, .c_spMenu__wrap03, .c_spMenu__btn').addClass('-ver01');
      }
    }
  }

  function modalMember(){
    $('[data-js-modalMember-elm01]').on('click',function(){
      const index = $(this).attr('data-js-modalMember-elm01');
      $('[data-js-modalMember-content01='+index+']').fadeIn(100).css('display','flex');
      // window.addEventListener('touchmove', disableScroll, { passive: false });
      // window.addEventListener('mousewheel', disableScroll, { passive: false });
      $('html').css('overflow', 'hidden');
    })
    $('[data-js-modalMember-btn01],[data-js-modalMember-content01]').on('click',function(){
      const index = $(this).attr('data-js-modalMember-elm01');
      $('[data-js-modalMember-content01]').fadeOut(100);
      // window.removeEventListener('touchmove', disableScroll, { passive: false });
      // window.removeEventListener('mousewheel', disableScroll, { passive: false });
      $('html').css('overflow', 'unset');
    })
    $('[data-js-modalMember-content02]').on('click',function(){
      event.stopPropagation();
    })
    function disableScroll(event) {
      event.preventDefault();
    }
  }

  function showScroll(){
    const elm = $('[data-js-showScroll-elm01]');
    // console.log(elm)
    const windowH = getWindowHeight();

    elm.each(function(){
      const targetTop = $(this).offset().top;
      // console.log(targetTop)
      // console.log('window.scrollYは'+window.scrollY)
      if(window.scrollY + windowH - 100 > targetTop){
        $(this).animate({
          'opacity': 1
        }, 300, 'linear');
      }
    })
  }

  function showBtnPagetop(){
    if(window.scrollY > 100){
      $('[data-js-showBtnPagetop-elm01]').animate({
        'opacity': 1
      }, 100, 'linear');
    }else{
      $('[data-js-showBtnPagetop-elm01]').animate({
        'opacity': 0
      }, 100, 'linear');
    }
  }

})();
(function() {
  jQuery.noConflict();
  jQuery(function($) {
    // ヘッダ高さ確保
    var cAttr = 'data-js_header';
    var vFixedElem = $('[' + cAttr + ']');
    var vSetHeightTarget = $(vFixedElem).parent();

    fSetHeaderHeight();

    $(window).resize(function(){
      fSetHeaderHeight();
    });

    function fSetHeaderHeight(){
      if($(vFixedElem).length){
        var vHeight = $(vFixedElem)[0].clientHeight;
        $(vSetHeightTarget).css('height', vHeight);
      }
    }
  });
})();
